<template>
	<div class="container">
		<div class="title-wrap">
			<h2 class="title">로그인</h2>
		</div>
		<form class="form" @submit.prevent="login">
			<el-input placeholder="이메일" v-model="email" clearable :disabled="!loginLoad"> </el-input>
			<el-input
				placeholder="비밀번호"
				v-model="password"
				:disabled="!loginLoad"
				show-password
			></el-input>
			<button class="btn-main btn-full mb-20" :disabled="!loginLoad">{{loginButtonText}}</button>
			<p class="error">{{loginErrorText}}</p>
			<el-divider>
				<span class="description">or</span>
			</el-divider>
			<div class="sns-login-wrap">
				<!-- <button class="btn-white btn-full">
					<div class="sns-img icon-large">
						<img src="@/assets/images/sns-google.svg" />
					</div>
					Google 로그인
				</button>
				<button class="btn-white btn-full">
					<div class="sns-img icon-large">
						<img src="@/assets/images/sns-kakao.svg" />
					</div>
					Kakao 로그인
				</button> -->
				<!-- <button class="btn-white btn-full">
					<div class="sns-img icon-large">
						<img src="@/assets/images/sns-naver.svg" />
					</div>
					Naver 로그인
				</button> -->
			</div>
			<br/>
			<hr />
			<div class="signup-wrap">
				<router-link to="/register" class="signup">회원가입</router-link>
				<router-link to="/findpw" class="find-password">비밀번호를 잃어버리셨나요?</router-link>
			</div>
		</form>
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from '../constants/config';
export default {
	data() {
		return {
			email: '',
			password: '',
			loginLoad: true,
			loginButtonText: "로그인",
			loginErrorText: ""
		}
	},
	methods: {
		login(){
			var vm = this;

			if(vm.email == "")
			{
				vm.loginErrorText = "이메일을 입력하세요."
				return;
			}
			var regEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; //eslint-disable-line
			if(this.email.match(regEmail) == null){
				vm.loginErrorText = "올바른 이메일을 입력하세요."
				return;
			}

			if(vm.password == "")
			{
				vm.loginErrorText = "비밀번호를 입력하세요."
				return;
			}

			vm.loginErrorText = "";
			vm.loginLoad = false;
			vm.loginButtonText = "로그인 중";
			axios.post(apiUrl+'/user/login',{
				email: vm.email,
				password: vm.password
			}, { withCredentials: true })
			.then(function(response) {
				if(response.data.success)
					vm.$router.push("/");
				else{
					vm.loginLoad = true;
					vm.loginButtonText = "로그인";
					vm.loginErrorText = response.data.message;
				}
			})
			.catch(function(error) {
				console.log(error);
			});
		}
	},
	beforeCreate(){
		if(this.$store.state.user.loggedIn)
			this.$router.push("/");
	}
}
</script>

<style scoped>
.signup-wrap {
	padding-top: 20px;
}

.signup-wrap .find-password {
	float: right;
}

.error {
	color: red;
	font-size: 14px;
}

.sns-login-wrap button {
	margin-bottom: 20px;
	border: 1px solid #e5e5e5;
}

.sns-login-wrap .sns-img {
	width: 24px;
	float: left;
	border-radius: 30px;
	overflow: hidden;
}
</style>
